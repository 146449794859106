import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Relift from "../../../images/media/products/relift/relift.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"





const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Smoothing eye serum - Relift" lang={"EN"}/>
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="magenta">
                    <H1>Smoothing eye serum</H1>
                    <p>Anti-Wrinkle & Dark Cricles <br />Time Control Eye Contour</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/relift/products/wygladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Smoothing eye serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Lifts and plumps your skin and reduce signs of ageing from skin sagging to expression lines, to fine lines and deep wrinkles.</li>
                        <li>Reduces puffiness and improves microcirculation. </li>
                        <li>Oxygenates and energizes skin cells.</li>
                        <li>Supports microbiome and restores protective skin barrier.</li>
                    </ul>
                    <Container>
                        <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-wygladzajace-serum-do-twarzy-15-ml-000000000000407515.html" color="magenta">Buy in Hebe</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-relift-wygladzajace-serum-pod-oczy-15-ml.html" color="magenta">Buy in Ziko Dermo</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-relift-vitamin-a-wygladzajace-serum-pod-oczy-15-ml-0107789" color="magenta">Buy in Gemini</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176928-Oxygenetic_Relift_Vitamin_A_wygladzajace_serum_pod_oczy_15_ml" color="magenta">Buy in DOZ</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/relift/products/wygladzajace-serum.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="liftingujace serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="magenta" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/ultra-repair-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/ultranaprawcze-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="ultranaprawcze serum"
                        />
                    </Link>
                    <Link to="/en/products/lifting-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/liftingujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="liftingujące serum"
                        />
                    </Link>
                    <Link to="/en/products/anti-aging-serum">
                        <StaticImage
                            src="../../images/media/products/relift/products/odmladzajace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="odmladzajace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="magenta">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="magenta">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                 <p><strong>OXYGENETIC RELIFT VITAMIN A Smoothing eye serum</strong></p>
                                <p>
                                Double correction eye serum & overnight wrinkle treatment. Serum instantly oxygenates skin around eyes to stimulate cell production and enhance its natural protective barrier. 
                                </p>
                                <p>
                                Effective to improve skin structure, elasticity  and bounce and restore plumpness for reduced appearance of wrinkles. Antidotum for saggy skin to brings back firmness, face contour and softness, reduce puffiness and dark circles and enjoy youthful looking, rejuvenated and revived skin around eyes. 
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/relift/products/wygladzajace-serum_single.png"
                                loading="lazy"
                                // width={534}
                                quality={95}
                                placeholder="none"
                                classNAme="productImg"
                                formats={["auto", "webp", "avif"]}
                                alt="wygladzajace-serum"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>How to use: </strong></p>
                                <p> Use twice daily as morning and night routine. Cleanse the eye area and apply the cream by patting gently. For external use only. Avoid direct contact with eyes. Avoid exposure to sun unless used with a suncreen.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>15ml</p>
                                <Relift/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Retinol with adenosine" desc="is a golden standard for anti-ageing, the combination that works effectively to replenish skin cells by allowing new plump skin cells to form. Stimulates collagen production  to keep the skin looking youthful. Trusted for real results. Demonstrated a sinificant reduction in the appearance of wrinkles by strengthening fibroblast proliferation in the dermal layer and promoting collagen synthesis. You will surely notice smooth, firmed and refined complexion" />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Synbiotics" desc="A blend of prebiotics and probiotics which fortify microbiom, our natural protective barrier, to protect against environmental stressors. Hydrate, nourish and smooth your skin." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active ingredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Stable polyphenol" desc="an esculine-derived ingredient which stimulates microcirculation and strengthens blood vessels. " />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="magenta" percentage="100" desc="badanych potwierdza, że po zastosowaniu skóra wokół oczu staje się wygładzona"/>
                    <EffectivnessCard color="magenta" percentage="93" desc="badanych potwierdza, że produkt działa dogłębnie i długotrwale regeneruje skórę"/>
                    <EffectivnessCard color="magenta" percentage="97" desc="badanych potwierdza, że regularne stosowanie zmniejsza cienie pod oczami"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
